import React, { Component } from 'react';
import Loading from './Loading';
import AttachedImages from './AttachedImages';
import AttachedAlbums from './AttachedAlbums';
import AttachedLinks from './AttachedLinks';
import AttachedVideos from './AttachedVideos';
import Socials from './Socials';
import Bandsintown from './Bandsintown';
import Newsletter from './Newsletter';
import textListenTo from './../assets/text-listen-to.png';
import textMusic from './../assets/text-music.png';
import textUpcomingShows from './../assets/text-upcoming-shows.png';
import textNewsletter from './../assets/text-newsletter.png';
import textContact from './../assets/text-contact.png';
//import i18n from '../i18n';

const API = 'https://admin.longtalljefferson.com/wp-json/wp/v2/';
const QUERY = 'one_pager/273/?_embed&filter[orderby]=menu_order&order=asc';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = { dataSet: [], isLoading: true }
    }
    
    componentDidMount() {
        let addLang = "";
        fetch(API+QUERY+addLang)
            .then(response => {
                if  (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Data unavailbale.');
                }
            })
        .then(response => { this.setState({ dataSet: response, isLoading: false }) })
        .catch(error => this.setState({ error, isLoading: false }));
    }

    render() {
        if (this.state.isLoading) {
            return <Loading />;
        }
        let content = this.state.dataSet.content.rendered;
        let albumEmbed = this.state.dataSet.acf.album_embed;
        let bandsintownId = this.state.dataSet.bandsintown_id;
        let attachedImages = this.state.dataSet.acf_photo_gallery;
        let attachedAlbums = this.state.dataSet.acf_photo_gallery_2;
        let attachedLinks = this.state.dataSet.acf_photo_gallery_3;
        let attachedVideos = this.state.dataSet.acf_photo_gallery_4;
        let socials= this.state.dataSet.acf_photo_gallery_5;
        let rbc = this.state.dataSet.rbc;
        let contact = this.state.dataSet.acf.contact;
        return (
            <div>
                <div className="socials">
                    <Socials data={socials} />
                </div>
                <div className="wrapperSqueeze">
                    <div className="landingPage">
                        <div className="blockFull">
                        <div className="blockText">
                                <div className="about padLow" dangerouslySetInnerHTML={{__html: content}}></div>
                                <div className="album padLow" dangerouslySetInnerHTML={{__html: albumEmbed}}></div>
                                <div className="anchor"><img src={textListenTo} className="title long" alt="Listen to Long Tall Jefferson"/></div>
                                <AttachedLinks data={attachedLinks} />
                                {/*<div className="anchor"><img src={textMusic} className="title" alt="Music"/></div>
                                <AttachedAlbums data={attachedAlbums} />*/}
                                <div className="anchor"><img src={textUpcomingShows} className="title" alt="Upcoming Shows"/></div>
                                <Bandsintown data={bandsintownId} />
                                {/*<div className="anchor">Videos</div>
                                <AttachedVideos data={attachedVideos} />
                                <div className="anchor">Press Photos</div>
                                <AttachedImages data={attachedImages} />*/}
                                <div className="anchor"><img src={textNewsletter} className="title" alt="Newsletter"/></div>
                                <Newsletter />
                                <div className="anchor"><img src={textContact} className="title" alt="Contact"/></div>
                                <div className="contact padLow" dangerouslySetInnerHTML={{__html: contact}}></div>
                                <div className="rbc padLow" dangerouslySetInnerHTML={{__html: rbc}}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;