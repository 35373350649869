import Analytics from 'analytics';
import googleAnalytics from '@analytics/google-analytics';


const analytics = Analytics({
  app: 'WEB LTJ',
  plugins: [
    googleAnalytics({
      trackingId: 'UA-167508217-3'
    })
  ]
})
analytics.page()
