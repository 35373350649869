import React, { Component } from 'react';
import Img from './Img';

class AttachedAlbums extends Component {
    constructor(props) {
        super(props);
        this.state = {dataSet: this.props.data }
    }
    
    componentDidMount() {
        this.setState({ isLoading: true });
    }

    render() {
        const { dataSet } = this.state;
        let images = dataSet.map((image, index) => {
            let imageSet = image.medium_srcset.split(" ");
            let imageSrc = imageSet[4];
            let imageTitle = image.title;
            let url = image.url;
            return (
                <div className="attachedAlbum" key={index}>
                   <a href={url} target="_blank" rel="noopener noreferrer">
                        <Img src={imageSrc} className="load" alt={imageTitle} />
                    </a>
                </div>
            )
        });
        return (
            <div className="attachedAlbums">
                { images }
            </div>
        );
    }
}

export default AttachedAlbums;